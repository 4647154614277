import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoadableComponent from '@loadable/component';
import qs from 'query-string';

const Demo = LoadableComponent(() => import(/* webpackChunkName: "demoIndex" */ './page/demo/Index'));
const X27 = LoadableComponent(() => import(/* webpackChunkName: "x27Index" */ './page/x27/Index'));
const X27v2 = LoadableComponent(() => import(/* webpackChunkName: "x27Index" */ './page/x27v2/Index'));
const yw11f = LoadableComponent(() => import(/* webpackChunkName: "yw11fIndex" */ './page/yw11f/Index'));

const DreamHouseHome = LoadableComponent(() => import(/* webpackChunkName: "x27Index" */ './page/dreamHouse/Index'));
const DreamHouseHome2 = LoadableComponent(() => import(/* webpackChunkName: "x27Index" */ './page/dreamHouse/Index2'));
const DreamHouseHome3 = LoadableComponent(() => import(/* webpackChunkName: "x27Index" */ './page/dreamHouse/Index3'));

const App = () => {
  /**处理地址栏token**/
  const { query, url } = qs.parseUrl(window.location.href);
  const isAndroid = /Android/i.test(navigator.userAgent);

  if (query && query['token']) {
    const { token, loginAuto, userInfo, ...queryOther } = query;
    const queryStr = qs.stringify(queryOther);
    localStorage.setItem('token', token as any);
    if (userInfo) {
      const uInfo = JSON.parse(decodeURIComponent(userInfo as any));
      localStorage.setItem('userInfo', JSON.stringify(uInfo));
      localStorage.setItem('userName', uInfo.userName);
    }
    // eslint-disable-next-line
    const goUrl = `${url}${queryStr ? '?' + queryStr : ''}`;
    // window.location.replace(goUrl)
  }

  // ipad  = ipad
  // iphone || android  = Mobile
  // other = pc
  const ua = navigator.userAgent;
  const isPhone = /(?:iPhone)/.test(ua);
  const isMobile = isPhone || isAndroid;
  // eslint-disable-next-line
  let platform = isMobile ? 'moblie' : 'ipad';

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Demo} />
        <Route exact path="/x27" component={X27} />
        <Route exact path="/x27v2" component={X27v2} />
        <Route exact path="/yw11f" component={yw11f} />
        <Route exact path="/dreamHouseHome" component={DreamHouseHome} />
        <Route exact path="/dreamHouseHome2" component={DreamHouseHome2} />
        <Route exact path="/dreamHouseHome3" component={DreamHouseHome3} />
        {/* <Redirect push to="/" /> */}
      </Switch>
    </Router>
  );
};

export default App;
